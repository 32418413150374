import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

//Import Breadcrumb

import DeleteModal from "../../Components/Common/DeleteModal";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

import { PatternFormat } from "react-number-format";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../helpers/url_helper";

const Transactions = () => {
  //meta title
  document.title = "Customers | Skote - React Admin & Dashboard Template";

  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: any) => state.ecommerce,
    (ecommerce) => ({
      transactionss: ecommerce.transactionss,
      loading: ecommerce.loading,
    })
  );

  const { transactionss, loading }: any = useSelector(selectProperties);

  const [isLoading, setLoading] = useState<boolean>(loading);

  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<any>(null);
  const [edittransactions, setEditTransactions] = useState<any>(null);
  const [deltransactions, setDelTransactions] = useState<any>(null);
  const [refreshData, setRefreshData] = useState(false);
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // setTransactions(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (edittransactions && edittransactions.id) || "",
      accountId: (edittransactions && edittransactions.accountId) || "",
      date: (edittransactions && edittransactions.date) || "",
      description: (edittransactions && edittransactions.description) || "",
      creditAmount: (edittransactions && edittransactions.creditAmount) || 0,
      debitAmount: (edittransactions && edittransactions.debitAmount) || 0,
    },

    validationSchema: Yup.object({
      accountId: Yup.number().required("Please Enter Your Account Id"),
      date: Yup.date().required("Please Enter Date"),
      description: Yup.string().required("Please Enter Description"),
      creditAmount: Yup.number(),
      debitAmount: Yup.number(),
    }),
    onSubmit: (values: any) => {
      console.log("on submit clicked");
      if (isEdit) {
        const updateTransaction = {
          transactionId: values.id,
          accountId: values.accountId,
          date: values.date,
          description: values.description,
          creditAmount: values.creditAmount,
          debitAmount: values.debitAmount,
        };
        // update transactions
        axios
          .post(`${BASE_URL}/trans/edit`, updateTransaction)
          .then((response) => {
            console.log(response);
            dispatch(onUpdateCustomer(updateTransaction));
            setRefreshData((prev) => !prev);
            setEditTransactions(null);
          })
          .catch((error) => {
            console.error("Editing Party failed:", error);
          });

        validation.resetForm();
      } else {
        console.log("add clicked");
        const newTransaction = {
          accountId: values["accountId"],
          date: values["date"],
          description: values["description"],
          creditAmount: values["creditAmount"],
          debitAmount: values["debitAmount"],
        };
        // save new transactions
        axios
          .post(`${BASE_URL}/trans/add`, newTransaction)
          .then((response) => {
            console.log(response);
            dispatch(onAddNewCustomer(newTransaction));
            setRefreshData((prev) => !prev);
          })
          .catch((error) => {
            console.error("Adding Transaction failed:", error);
          });

        validation.resetForm();
      }
      toggle();
    },
  });

  const handleCustomerClick = useCallback(
    (arg: any) => {
      const transactions = arg;

      setEditTransactions({
        id: transactions.id,
        accountId: transactions.accountId,
        date: transactions.date,
        description: transactions.description,
        creditAmount: transactions.creditAmount,
        debitAmount: transactions.debitAmount,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const data = [
    { id: "1", country: "Pakistan", factor: "1.2" },
    { id: "2", country: "India", factor: "1.5" },
    { id: "3", country: "USA", factor: "1.8" },
    { id: "4", country: "China", factor: "1.3" },
    { id: "5", country: "Australia", factor: "1.6" },
  ];

  // Customber Column
  const columns = useMemo(
    () => [
      {
        header: "Transaction ID",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Account ID",
        accessorKey: "accountId",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Debit Amount",
        accessorKey: "debitAmount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Credit Amount",
        accessorKey: "creditAmount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Balance",
        accessorKey: "balance",
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: "Rating",
      //   accessorKey: "rating",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps: any) => {
      //     return <span className="badge bg-success font-size-12"><i className="mdi mdi-star me-1"></i> {cellProps.getValue()}</span>
      //   }
      // },
      // {
      //   header: "Carbon Credits",
      //   accessorKey: "factor",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Dollar Amount",
      //   accessorKey: "factor",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Joining Date",
      //   accessorKey: "date",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                {" "}
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const transactionsData = cellProps.row.original;
                    handleCustomerClick(transactionsData);
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>{" "}
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {" "}
                    Edit{" "}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  href="#"
                  onClick={() => {
                    const transactionsData = cellProps.row.original;
                    onClickDelete(transactionsData);
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {" "}
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [handleCustomerClick]
  );

  //delete transactions
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (transactions: any) => {
    setDelTransactions(transactions);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
    if (deltransactions.id) {
      axios
        .post(`${BASE_URL}/trans/delete`, { transactionId: deltransactions.id })
        .then((response) => {
          console.log("Transaction deleted successfully", response);
          dispatch(onDeleteCustomer(deltransactions.id));
          setRefreshData((prev) => !prev);
        })
        .catch((error) => {
          console.error("Delete failed:", error);
        });
    }
    setDeleteModal(false);
  };

  const getTransactions = () => {
    axios
      .get(`${BASE_URL}/trans/`)
      .then((response) => {
        console.log("API response:", response);
        setTransactions(response);
        // Handle the response data
      })
      .catch((error) => {
        console.error("Request failed:", error);
        // Handle the error
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getTransactions();
        console.log("getTransactions called successfully");
      } catch (error) {
        console.error("Error calling getTransactions:", error);
      }
    };

    fetchData();
  }, [refreshData]);

  const handleCustomerClicks = () => {
    setIsEdit(false);
    // setTransactions("");
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Customers" /> */}
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={transactions || []}
                    isGlobalFilter={true}
                    isAddButton={true}
                    isExpButton={true}
                    isPagination={true}
                    isCustomPageSize={true}
                    handleUserClick={handleCustomerClicks}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
                    buttonName=" New Transaction"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    pagination="pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Transaction" : "Add transaction"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>Account Id</Label>
                      <Input
                        name="accountId"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.accountId || ""}
                        placeholder="Insert account id"
                        invalid={
                          validation.touched.accountId &&
                          validation.errors.accountId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.accountId &&
                      validation.errors.accountId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.accountId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Date</Label>
                      <Flatpickr
                        type="date"
                        name="date"
                        className="form-control d-block"
                        placeholder="Select date"
                        options={{
                          mode: "single",
                          dateFormat: "d M, Y",
                        }}
                        value={validation.values.date}
                        onChange={(date: any) =>
                          validation.setFieldValue(
                            "date",
                            moment(date[0]).format("DD MMM ,YYYY")
                          )
                        }
                      />
                      {validation.touched.date && validation.errors.date ? (
                        <span className="text-danger">
                          {" "}
                          {validation.errors.date}{" "}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Description</Label>
                      <Input
                        name="description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        placeholder="Insert description"
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label> Credit Amount </Label>
                      <Input
                        type="number"
                        name="creditAmount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.creditAmount || ""}
                        placeholder="Insert credit amount"
                        invalid={
                          validation.touched.creditAmount &&
                          validation.errors.creditAmount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.creditAmount &&
                      validation.errors.creditAmount ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.creditAmount}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label> Debit Amount </Label>
                      <Input
                        type="number"
                        name="debitAmount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.debitAmount || ""}
                        placeholder="Insert debit amount"
                        invalid={
                          validation.touched.debitAmount &&
                          validation.errors.debitAmount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.debitAmount &&
                      validation.errors.debitAmount ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.debitAmount}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-transactions"
                      >
                        {!!isEdit ? "Update" : "Add"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Transactions;
