import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { BASE_URL } from "../../helpers/url_helper";
//Import Breadcrumb

import DeleteModal from "../../Components/Common/DeleteModal";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

import { PatternFormat } from "react-number-format";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";

const Payments = () => {
  //meta title
  document.title = "Customers | Skote - React Admin & Dashboard Template";

  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: any) => state.ecommerce,
    (ecommerce) => ({
      payments: ecommerce.payments,
      loading: ecommerce.loading,
    })
  );

  const { payments, loading }: any = useSelector(selectProperties);

  const [isLoading, setLoading] = useState<boolean>(loading);

  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [payment, setPayment] = useState<any>(null);
  const [refreshData, setRefreshData] = useState(false);
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // setPayment(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      invoiceId: (payment && payment.country) || "",
      date: (payment && payment.factor) || "",
      amount: (payment && payment.factor) || "",
      paymentType: (payment && payment.factor) || "",
      checkNumber: (payment && payment.factor) || "",
    },

    validationSchema: Yup.object({
      invoiceId: Yup.number().required("Please Enter Your Payments"),
      date: Yup.date().required("Please Enter Your Factor"),
      amount: Yup.number().required("Please Enter Your Factor"),
      paymentType: Yup.string().required("Please Enter Your Factor"),
      checkNumber: Yup.string().required("Please Enter Your Factor"),
    }),
    onSubmit: (values: any) => {
      if (isEdit) {
        const updateCustomer = {
          id: payment ? payment.id : 0,
          country: values.country,
          factor: values.factor,
        };
        // update payment
        dispatch(onUpdateCustomer(updateCustomer));
        validation.resetForm();
      } else {
        const newCustomer = {
          invoiceId: values["invoiceId"],
          date: values["date"],
          amount: values["amount"],
          paymentType: values["paymentType"],
          checkNumber: values["checkNumber"],
        };
        axios
          .post(`${BASE_URL}/ipayment/add`, newCustomer)
          .then((response) => {
            console.log(response);
            dispatch(onAddNewCustomer(newCustomer));
            setRefreshData((prev) => !prev);
          })
          .catch((error) => {
            console.error("Adding Products failed:", error);
          });
        // save new payment

        validation.resetForm();
      }
      toggle();
    },
  });

  const handleCustomerClick = useCallback(
    (arg: any) => {
      const payment = arg;

      setPayment({
        invoiceId: payment.invoiceId,
        date: payment.date,
        amount: payment.amount,
        paymentType: payment.paymentType,
        checkNumber: payment.checkNumber,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const data = [
    { id: "1", country: "Pakistan", factor: "1.2" },
    { id: "2", country: "India", factor: "1.5" },
    { id: "3", country: "USA", factor: "1.8" },
    { id: "4", country: "China", factor: "1.3" },
    { id: "5", country: "Australia", factor: "1.6" },
  ];

  // Customber Column
  const columns = useMemo(
    () => [
      {
        header: "Payment ID",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Invoice ID",
        accessorKey: "invoiceId",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Payment Type",
        accessorKey: "paymentType",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Check Number",
        accessorKey: "checkNumber",
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: "Rating",
      //   accessorKey: "rating",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps: any) => {
      //     return <span className="badge bg-success font-size-12"><i className="mdi mdi-star me-1"></i> {cellProps.getValue()}</span>
      //   }
      // },
      // {
      //   header: "Carbon Credits",
      //   accessorKey: "factor",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Dollar Amount",
      //   accessorKey: "factor",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Joining Date",
      //   accessorKey: "joiningDate",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Action",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: (cellProps: any) => {
      //     return (
      //       <UncontrolledDropdown>
      //         <DropdownToggle tag="a" href="#" className="card-drop">
      //           {" "}
      //           <i className="mdi mdi-dots-horizontal font-size-18"></i>
      //         </DropdownToggle>
      //         <DropdownMenu className="">
      //           <DropdownItem
      //             href="#"
      //             onClick={() => {
      //               const paymentData = cellProps.row.original;
      //               handleCustomerClick(paymentData);
      //             }}
      //           >
      //             <i
      //               className="mdi mdi-pencil font-size-16 text-success me-1"
      //               id="edittooltip"
      //             ></i>{" "}
      //             Edit
      //             <UncontrolledTooltip placement="top" target="edittooltip">
      //               {" "}
      //               Edit{" "}
      //             </UncontrolledTooltip>
      //           </DropdownItem>

      //           <DropdownItem
      //             href="#"
      //             onClick={() => {
      //               const paymentData = cellProps.row.original;
      //               onClickDelete(paymentData);
      //             }}
      //           >
      //             <i
      //               className="mdi mdi-trash-can font-size-16 text-danger me-1"
      //               id="deletetooltip"
      //             ></i>
      //             Delete
      //             <UncontrolledTooltip placement="top" target="deletetooltip">
      //               {" "}
      //               Delete
      //             </UncontrolledTooltip>
      //           </DropdownItem>
      //         </DropdownMenu>
      //       </UncontrolledDropdown>
      //     );
      //   },
      // },
    ],
    [handleCustomerClick]
  );

  //delete payment
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (payment: any) => {
    setPayment(payment);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
    if (payment.id) {
      dispatch(onDeleteCustomer(payment.id));
    }
    setDeleteModal(false);
  };

  const getPayments = () => {
    axios
      .get(`${BASE_URL}/ipayment/`)
      .then((response) => {
        setPayment(response);
      })
      .catch((error) => {
        console.error("Request failed:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPayments();
        console.log("getPayments called successfully");
      } catch (error) {
        console.error("Error calling getinvoice:", error);
      }
    };

    fetchData();
  }, [refreshData]);

  const handleCustomerClicks = () => {
    setIsEdit(false);
    // setPayment("");
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Customers" /> */}
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={payment || []}
                    isGlobalFilter={true}
                    isAddButton={true}
                    isExpButton={false}
                    isPagination={true}
                    isCustomPageSize={true}
                    handleUserClick={handleCustomerClicks}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
                    buttonName=" New Payment"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    pagination="pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Customer" : "Add Customer"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>Invoice Id</Label>
                      <Input
                        name="invoiceId"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.invoiceId || ""}
                        placeholder="Insert invoice Id"
                        invalid={
                          validation.touched.invoiceId &&
                          validation.errors.invoiceId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.invoiceId &&
                      validation.errors.invoiceId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.invoiceId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Date</Label>
                      <Flatpickr
                        type="date"
                        name="date"
                        className="form-control d-block"
                        placeholder="Select date"
                        options={{
                          mode: "single",
                          dateFormat: "d M, Y",
                        }}
                        value={validation.values.date}
                        onChange={(date: any) =>
                          validation.setFieldValue(
                            "date",
                            moment(date[0]).format("DD MMM ,YYYY")
                          )
                        }
                      />
                      {validation.touched.date && validation.errors.date ? (
                        <span className="text-danger">
                          {" "}
                          {validation.errors.date}{" "}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label> Amount </Label>
                      <Input
                        type="number"
                        name="amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.amount || ""}
                        placeholder="Insert amount"
                        invalid={
                          validation.touched.amount && validation.errors.amount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.amount && validation.errors.amount ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.amount}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label> Payment Type </Label>
                      <Input
                        type="text"
                        name="paymentType"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentType || ""}
                        placeholder="Insert payment Type"
                        invalid={
                          validation.touched.paymentType &&
                          validation.errors.paymentType
                            ? true
                            : false
                        }
                      />
                      {validation.touched.paymentType &&
                      validation.errors.paymentType ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.paymentType}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label> Check Number </Label>
                      <Input
                        type="text"
                        name="checkNumber"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.checkNumber || ""}
                        placeholder="Insert Check Number"
                        invalid={
                          validation.touched.checkNumber &&
                          validation.errors.checkNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.checkNumber &&
                      validation.errors.checkNumber ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.checkNumber}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-payment"
                      >
                        {!!isEdit ? "Update" : "Add"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Payments;
