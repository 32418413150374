import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Input, FormFeedback, Label, Form, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { BASE_URL } from "../../../helpers/url_helper";
//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

import { PatternFormat } from "react-number-format";


//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { createSelector } from 'reselect';
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";

const EcommerceCustomers = () => {
  //meta title
  document.title = "Customers | Skote - React Admin & Dashboard Template";

  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: any) => state.ecommerce,
    (ecommerce) => ({
      products: ecommerce.products,
      loading: ecommerce.loading
    })
  );

  const { products, loading }: any = useSelector(selectProperties);
  // console.log("products",products);
  const [isLoading, setLoading] = useState<boolean>(loading)

  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(null);
  const [item, setItem] = useState<any>(null);
  const [color, setColor] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);
  const [editproduct, setEditProduct] = useState<any>(null);
  const [delproduct, setDelProduct] = useState<any>(null);
  const [refreshData, setRefreshData] = useState(false);
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // setProduct(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (editproduct && editproduct.id) || "",
      description: (editproduct && editproduct.description) || "",
      color: (editproduct && editproduct.color) || "",
      unitPrice: (editproduct && editproduct.unitPrice) || "",
      category: (editproduct && editproduct.category) || "",
      noOfItems: (editproduct && editproduct.noOfItems) || "",
      quantityPerItem: (editproduct && editproduct.quantityPerItem) || "",
      unitMeasure: (editproduct && editproduct.unitMeasure) || "",
    },

    validationSchema: Yup.object({
      description: Yup.string().required("Please Enter Your Name"),
      color: Yup.string().required("Please Enter Your color"),
      unitPrice: Yup.number().required("Please Enter Your unit price"),
      category: Yup.string().required("Please Enter Your Category"),
      noOfItems: Yup.number().required("Please Enter Your no Of Items"),
      quantityPerItem: Yup.number().required("Please Enter Your quantity Per Item"),
      unitMeasure: Yup.string().required("Please Enter Your unit Measure"),
    }),
    onSubmit: (values: any) => {

      if (isEdit) {
        const updateCustomer = {
          id: values.id,
          description: values.description,
          color: values.color,
          unitPrice: values.unitPrice,
          category: values.category,
          noOfItems: values.noOfItems,
          quantityPerItem: values.quantityPerItem,
          unitMeasure: values.unitMeasure,
        };
        // update product
        axios.post(`${BASE_URL}/Attributes/edit`, updateCustomer)
          .then(response => {
            console.log(response);
            dispatch(onUpdateCustomer(updateCustomer));
            setRefreshData(prev => !prev);
            setEditProduct(null)
          })
          .catch(error => {

            console.error('Editing Products failed:', error);

          });

        validation.resetForm();
      } else {
        console.log("add clicked");
        const newCustomer = {
          description: values["description"],
          color: values["color"],
          unitPrice: values["unitPrice"],
          category: values["category"],
          noOfItems: values["noOfItems"],
          quantityPerItem: values["quantityPerItem"],
          unitMeasure: values["unitMeasure"],
        };
        // save new product
        axios.post(`${BASE_URL}/Attributes/add`, newCustomer)
          .then(response => {
            console.log(response);
            dispatch(onAddNewCustomer(newCustomer));
            setRefreshData(prev => !prev);
          })
          .catch(error => {

            console.error('Adding Products failed:', error);

          });
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleCustomerClick = useCallback((arg: any) => {
    const product = arg;
    console.log("product edit data", product);
    setEditProduct({
      id: product.id,
      description: product?.product?.description,
      color: product?.color?.name || '',
      unitPrice: product?.unitPrice,
      category: product?.category?.name || '',
      noOfItems: product?.noOfItems,
      quantityPerItem: product?.quantityPerItem,
      unitMeasure: product?.unitMeasure,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Customber Column
  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Description",
        accessorKey: "product",
        cell: (cellProps: any) => {
          return cellProps.row.original.product.description || '';
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Color",
        accessorKey: "color",
        cell: (cellProps: any) => {
          return cellProps.row.original.color.name || '';
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Category",
        accessorKey: "category",
        cell: (cellProps: any) => {
          return cellProps.row.original.category.name || '';
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "No of Item",
        accessorKey: "noOfItems",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Quantity Per Item",
        accessorKey: "quantityPerItem",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Sold Items",
        accessorKey: "soldItems",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Unit Measure",
        accessorKey: "unitMeasure",
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: "Rating",
      //   accessorKey: "rating",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps: any) => {
      //     return <span className="badge bg-success font-size-12"><i className="mdi mdi-star me-1"></i> {cellProps.getValue()}</span>
      //   }
      // },
      // {
      //   header: "Carbon Credits",
      //   accessorKey: "unitPrice",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Unit Price",
        accessorKey: "unitPrice",
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: "Joining Date",
      //   accessorKey: "joiningDate",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop"> <i className="mdi mdi-dots-horizontal font-size-18"></i></DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const productData = cellProps.row.original;
                    handleCustomerClick(productData);
                  }}>
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i> Edit
                  <UncontrolledTooltip placement="top" target="edittooltip"> Edit </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  href="#"
                  onClick={() => {
                    const productData = cellProps.row.original;
                    onClickDelete(productData);
                  }} >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip" ></i>Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip"> Delete</UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [handleCustomerClick]
  );

  //delete product
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (product: any) => {
    setDelProduct(product);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
    if (delproduct.id) {
      console.log("delete id", delproduct.id);

      axios.post(`${BASE_URL}/Attributes/delete`, { productId: delproduct.id })
        .then(response => {
          console.log("product deleted successfully", response);
          dispatch(onDeleteCustomer(delproduct.id));
          setRefreshData(prev => !prev);
        })
        .catch(error => {

          console.error('Delete failed:', error);

        });
      // dispatch(onDeleteCustomer(product.id));
    }
    setDeleteModal(false);
  };

  const getProducts = () => {
    axios.get(`${BASE_URL}/Attributes/`)
      .then(response => {
        // console.log("API response:", response);
        // console.log("API response color:", response[0].attributes[0].color.name);
        setProduct(response);
        // Handle the response data
      })
      .catch(error => {
        console.error('Request failed:', error);
        // Handle the error
      });
  }
  const getItem = () => {
    axios.get(`${BASE_URL}/Attributes/item`)
      .then(response => {
        // console.log("dropdown item:", response);
        // console.log("API response color:", response[0].attributes[0].color.name);
        setItem(response);
        // Handle the response data
      })
      .catch(error => {
        console.error('Request failed:', error);
        // Handle the error
      });
  }
  const getColor = () => {
    axios.get(`${BASE_URL}/Attributes/Color`)
      .then(response => {
        // console.log("dropdown color:", response);
        // console.log("API response color:", response[0].attributes[0].color.name);
        setColor(response);
        // Handle the response data
      })
      .catch(error => {
        console.error('Request failed:', error);
        // Handle the error
      });
  }
  const getCategory = () => {
    axios.get(`${BASE_URL}/Attributes/category`)
      .then(response => {
        // console.log("dropdown category:", response);
        // console.log("API response color:", response[0].attributes[0].color.name);
        setCategory(response);
        // Handle the response data
      })
      .catch(error => {
        console.error('Request failed:', error);
        // Handle the error
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getProducts();
        await getItem();
        await getColor();
        await getCategory();
        console.log("getProducts called successfully");
      } catch (error) {
        console.error("Error calling getProducts:", error);
      }
    };

    fetchData();
  }, [refreshData]);
  // useEffect(() => {
  //   // dispatch(onGetCustomers());
  //   getProducts();
  //   console.log("on get called");
  // }, []);

  const handleCustomerClicks = () => {
    setIsEdit(false);
    // setProduct("");
    toggle();
  };
  const updateInputValue = (value) => {
    validation.setValues({ ...validation.values, description: value });
  };
  const updateInputValue2 = (value) => {
    validation.setValues({ ...validation.values, color: value });
  };
  const updateInputValue3 = (value) => {
    validation.setValues({ ...validation.values, category: value });
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Customers" /> */}
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={product || []}
                      isGlobalFilter={true}
                      isAddButton={true}
                      isExpButton={false}
                      isPagination={true}
                      isCustomPageSize={true}
                      handleUserClick={handleCustomerClicks}
                      buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
                      buttonName=" New Material"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      pagination="pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={() => {
              setModal(false)
              setEditProduct(null)
            }} tag="h4">
              {!!isEdit ? "Edit Material" : "Add Material"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>Description</Label>
                      <UncontrolledDropdown>
                        <DropdownToggle style={{ backgroundColor: 'white', border: 'none', width: '104%', marginLeft: '-10px' }}>
                          <Input
                            name="description"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""} // Adjust 'dropdownValue' to match the value in your validation object
                            placeholder="search placeholder"
                            invalid={
                              validation.touched.description && validation.errors.description ? true : false
                            }
                          />
                        </DropdownToggle>
                        <DropdownMenu className="">
                          {item?.map((item) => (
                            <DropdownItem key={item?.id} href="#" onClick={() => updateInputValue(item?.description)}>
                              {item?.description}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    {/* <div className="mb-3">
                      <Label>Description</Label>
                      <Input
                        name="description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        placeholder="Insert description"
                        invalid={
                          validation.touched.description && validation.errors.description ? true : false
                        }
                      />
                      {validation.touched.description && validation.errors.description ?
                        (<FormFeedback type="invalid">{validation.errors.description}</FormFeedback>) : null}
                    </div> */}
                    <div className="mb-3">
                      <Label>Color</Label>
                      {/* <Input
                        name="color"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.color || ""}
                        placeholder="Insert color"
                        invalid={
                          validation.touched.color && validation.errors.color ? true : false}
                      />
                      {validation.touched.color && validation.errors.color ?
                        (<FormFeedback type="invalid">  {validation.errors.color} </FormFeedback>) : null} */}
                      <UncontrolledDropdown>
                        <DropdownToggle style={{ backgroundColor: 'white', border: 'none', width: '104%', marginLeft: '-10px' }}>
                          <Input
                            name="color"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.color || ""} // Adjust 'dropdownValue' to match the value in your validation object
                            placeholder="search placeholder"
                            invalid={
                              validation.touched.color && validation.errors.color ? true : false
                            }
                          />
                        </DropdownToggle>
                        <DropdownMenu className="">
                          {color?.map((color) => (
                            <DropdownItem key={color?.id} href="#" onClick={() => updateInputValue2(color?.name)}>
                              {color?.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>  
                    </div>
                    <div className="mb-3">
                      <Label>  Unit Price </Label>
                      <Input
                        type="number"
                        name="unitPrice"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.unitPrice || ''}
                        placeholder="Insert unit price"
                        invalid={
                          validation.touched.unitPrice && validation.errors.unitPrice ? true : false
                        }
                      />
                      {validation.touched.unitPrice && validation.errors.unitPrice ?
                        (<FormFeedback type="invalid"> {validation.errors.unitPrice} </FormFeedback>) : null}
                    </div>
                    <div className="mb-3">
                      <Label>  Category </Label>
                      {/* <Input
                        type="text"
                        name="category"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.category || ''}
                        placeholder="Insert Category"
                        invalid={
                          validation.touched.category && validation.errors.category ? true : false
                        }
                      />
                      {validation.touched.category && validation.errors.category ?
                        (<FormFeedback type="invalid"> {validation.errors.category} </FormFeedback>) : null} */}
                         <UncontrolledDropdown>
                        <DropdownToggle style={{ backgroundColor: 'white', border: 'none', width: '104%', marginLeft: '-10px' }}>
                          <Input
                            name="category"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.category || ""} // Adjust 'dropdownValue' to match the value in your validation object
                            placeholder="search placeholder"
                            invalid={
                              validation.touched.category && validation.errors.category ? true : false
                            }
                          />
                        </DropdownToggle>
                        <DropdownMenu className="">
                          {category?.map((category) => (
                            <DropdownItem key={category?.id} href="#" onClick={() => updateInputValue3(category?.name)}>
                              {category?.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>  
                    </div>
                    <div className="mb-3">
                      <Label>  No Of Items </Label>
                      <Input
                        type="number"
                        name="noOfItems"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.noOfItems || ''}
                        placeholder="Insert no Of Items"
                        invalid={
                          validation.touched.noOfItems && validation.errors.noOfItems ? true : false
                        }
                      />
                      {validation.touched.noOfItems && validation.errors.noOfItems ?
                        (<FormFeedback type="invalid"> {validation.errors.noOfItems} </FormFeedback>) : null}
                    </div>
                    <div className="mb-3">
                      <Label>  Quantity Per Item </Label>
                      <Input
                        type="number"
                        name="quantityPerItem"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.quantityPerItem || ''}
                        placeholder="Insert quantity Per Item"
                        invalid={
                          validation.touched.quantityPerItem && validation.errors.quantityPerItem ? true : false
                        }
                      />
                      {validation.touched.quantityPerItem && validation.errors.quantityPerItem ?
                        (<FormFeedback type="invalid"> {validation.errors.quantityPerItem} </FormFeedback>) : null}
                    </div>
                    <div className="mb-3">
                      <Label>  Unit Measure </Label>
                      <Input
                        type="text"
                        name="unitMeasure"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.unitMeasure || ''}
                        placeholder="Insert unit measure"
                        invalid={
                          validation.touched.unitMeasure && validation.errors.unitMeasure ? true : false
                        }
                      />
                      {validation.touched.unitMeasure && validation.errors.unitMeasure ?
                        (<FormFeedback type="invalid"> {validation.errors.unitMeasure} </FormFeedback>) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button type="submit" color="success" className="save-product" >{!!isEdit ? "Update" : "Add"}</Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default EcommerceCustomers;
