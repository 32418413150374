import React, { useCallback, useState } from "react"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap"

// Image
// import img7 from "../../../assets/images/product/img-7.png"
// import img4 from "../../../assets/images/product/img-4.png"

const CustomersModal = (props?: any) => {
  const [modal1, setModal1] = useState<boolean>(false);
  const { isOpen, toggle, editDetails } = props;
  console.log("edit details",editDetails);
  // console.log("edit id",editDetails.InvoiceDetails[0].id);
  const toggleViewModal = useCallback((data: any) => { setModal1(!modal1);  }, [modal1]);
  // console.log("edit details",editDetails.Accounts[0].accountCode);
  // const invoiceIds = editDetails.InvoiceDetails.map(detail => detail || '');
  // console.log("invoiceIds",invoiceIds);
  const accountTitle = editDetails?.Accounts?.[0]?.accountTitle || '';
  const iban = editDetails?.Accounts?.[0]?.iban || '';
  const total = editDetails?.InvoiceDetails?.reduce((acc, data) => {
    return acc + data.lineTotal;
  }, 0);

  return (
    <>
    {/* <AddDeviceModal isOpen={modal1} toggle={toggleViewModal} editDetails={editDetails} /> */}
    <Modal isOpen={isOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex={-1} toggle={toggle} >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>
          <h5 className="modal-title">Invoice Details</h5></ModalHeader>
        <ModalBody>
          {/* <p className="mb-2">
            User Name: <span className="text-primary">{editDetails?.Name}</span>
          </p>
          <p className="mb-4">
            User Email: <span className="text-primary">{editDetails?.billingName}</span>
          </p> */}
         
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  {/* <th scope="col">Invoice Id</th> */}
                  <th scope="col">Color</th>
                  <th scope="col">Category</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Line Total</th>
                  {/* <th scope="col">Unit Measure</th> */}
                </tr>
              </thead>
              <tbody>
          {editDetails?.InvoiceDetails?.map((detail, index) => (
            <tr key={index}>
              <td>
                <div>
                  <h5 className="text-truncate font-size-14">{detail.id}</h5>
                </div>
              </td>
              {/* <td>{detail.invoiceId}</td> */}
              <td>{detail.product.color.name}</td>
              <td>{detail.product.category.name}</td>
              <td>{detail.product.unitPrice}</td>
              <td>{detail.quantity}</td>
              <td>{detail.lineTotal}</td>
              {/* <td>{detail.unitMeasure}</td> */}
            </tr>
          ))}
        </tbody>
            </Table>
          </div>
            <Row>
                  <Col>
                    <div className="text-end">
                      <h3>Total : {total}</h3>
                    </div>
                  </Col>
                </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </div>
    </Modal>
    </>
  );
}

export default CustomersModal
