import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Table,
} from "reactstrap";

import { BASE_URL } from "../../helpers/url_helper";
//Import Breadcrumb

import DeleteModal from "../../Components/Common/DeleteModal";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

import { PatternFormat } from "react-number-format";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import CustomersModal from "./CustomersModal";

interface FormData {
  description: string;
  quantity: number;
  unitPrice: number;
}
const Invoice = () => {
  //meta title
  document.title = "Customers | Skote - React Admin & Dashboard Template";

  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: any) => state.ecommerce,
    (ecommerce) => ({
      invoices: ecommerce.invoices,
      loading: ecommerce.loading,
    })
  );

  const { invoices, loading }: any = useSelector(selectProperties);

  const [isLoading, setLoading] = useState<boolean>(loading);

  const [modal, setModal] = useState<boolean>(false);
  const [modal1, setModal1] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<any>(null);
  const [product, setProduct] = useState<any>(null);
  const [editinvoice, setEditInvoice] = useState<any>(null);
  const [delinvoice, setDelInvoice] = useState<any>(null);
  const [refreshData, setRefreshData] = useState(false);

  const dummy = [
    {
      description: "item 1",
      quantity: 5,
      unitPrice: 20,
    },
    {
      description: "item 2",
      quantity: 10,
      unitPrice: 30,
    },
  ];
  const [formDataArray, setFormDataArray] = useState<FormData[]>([]);
  console.log("form data", formDataArray);
  // Function to handle addition of form values to array
  const handleAddToArray = () => {
    const newData: FormData = {
      description: validation.values.description || "",
      quantity: validation.values.quantity || 0,
      unitPrice: validation.values.unitPrice || 0,
    };
    setFormDataArray((prevArray) => [...prevArray, newData]);
  };
  const handleRemoveFromArray = (index: number) => {
    setFormDataArray((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });
  };
  const total = formDataArray.reduce((acc, data) => {
    return acc + data.quantity * data.unitPrice;
  }, 0);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // setInvoice(null);
    } else {
      setModal(true);
    }
  }, [modal]);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (editinvoice && editinvoice.id) || "",
      partyId: (editinvoice && editinvoice.partyId) || "",
      quantity: (editinvoice && editinvoice.quantity) || "",
      description: (editinvoice && editinvoice.description) || "",
      unitPrice: (editinvoice && editinvoice.unitPrice) || "",
      date: formattedDate,
    },

    validationSchema: Yup.object({
      partyId: Yup.number().required("Please Enter Your partyid"),
      quantity: Yup.number(),
      description: Yup.string(),
      unitPrice: Yup.number(),
      date: Yup.date(),
    }),
    onSubmit: (values: any) => {
      if (isEdit) {
        const updateInvoice = {
          id: values.id,
          partyId: values.partyId,
          quantity: values.quantity,
          date: formattedDate,
          products: formDataArray,
        };
        // update invoice
        axios
          .post(`${BASE_URL}/Invoice/edit`, updateInvoice)
          .then((response) => {
            console.log(response);
            dispatch(onUpdateCustomer(updateInvoice));
            setRefreshData((prev) => !prev);
            setEditInvoice(null);
          })
          .catch((error) => {
            console.error("Editing Party failed:", error);
          });

        validation.resetForm();
      } else {
        console.log("add clicked");
        const newCustomer = {
          partyId: values["partyId"],
          quantity: values["quantity"],
          date: formattedDate,
          products: formDataArray,
        };
        axios
          .post(`${BASE_URL}/Invoice/add`, newCustomer)
          .then((response) => {
            console.log(response);
            dispatch(onAddNewCustomer(newCustomer));
            setRefreshData((prev) => !prev);
            setFormDataArray([]);
          })
          .catch((error) => {
            console.error("Adding Products failed:", error);
          });

        validation.resetForm();
      }
      toggle();
    },
  });
  const [editDetails, setEditDetails] = useState<any>("");
  const toggleViewModal = useCallback(
    (data: any) => {
      setModal1(!modal1);
      setEditDetails(data);
    },
    [modal1]
  );

  const handleCustomerClick = useCallback(
    (arg: any) => {
      const invoice = arg;
      console.log("invoice data", invoice);
      setEditInvoice({
        id: invoice.id,
        partyId: invoice.partyId,
        quantity: invoice.quantity,
        date: formattedDate,
        products: formDataArray,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Customber Column
  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Party ID",
        accessorKey: "partyId",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Total Amount",
        accessorKey: "totalAmount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Paid Amount",
        accessorKey: "paidAmount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Detail",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                const partyData = cellProps.row.original;
                toggleViewModal(partyData);
              }}
            >
              View Details
            </Button>
          );
        },
      },

      // {
      //   header: "Rating",
      //   accessorKey: "rating",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps: any) => {
      //     return <span className="badge bg-success font-size-12"><i className="mdi mdi-star me-1"></i> {cellProps.getValue()}</span>
      //   }
      // },
      // {
      //   header: "Carbon Credits",
      //   accessorKey: "quantity",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Dollar Amount",
      //   accessorKey: "quantity",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Joining Date",
      //   accessorKey: "joiningDate",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                {" "}
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="">
                {/* <DropdownItem
                  href="#"
                  onClick={() => {
                    const invoiceData = cellProps.row.original;
                    handleCustomerClick(invoiceData);
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>{" "}
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {" "}
                    Edit{" "}
                  </UncontrolledTooltip>
                </DropdownItem> */}

                <DropdownItem
                  href="#"
                  onClick={() => {
                    const invoiceData = cellProps.row.original;
                    onClickDelete(invoiceData);
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {" "}
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [handleCustomerClick]
  );

  //delete invoice
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (invoice: any) => {
    setDelInvoice(invoice);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
    if (delinvoice.id) {
      console.log("delete id", delinvoice.id);

      axios
        .post(`${BASE_URL}/Invoice/delete`, { invoiceId: delinvoice.id })
        .then((response) => {
          console.log("invoice deleted successfully", response);
          dispatch(onDeleteCustomer(invoice.id));
          setRefreshData((prev) => !prev);
        })
        .catch((error) => {
          console.error("Delete failed:", error);
        });
      // dispatch(onDeleteCustomer(product.id));
    }
    setDeleteModal(false);
  };

  const getProducts = () => {
    axios
      .get(`${BASE_URL}/Attributes/`)
      .then((response) => {
        // console.log("API response:", response);
        // console.log("API response color:", response[0].attributes[0].color.name);
        setProduct(response);
        // Handle the response data
      })
      .catch((error) => {
        console.error("Request failed:", error);
        // Handle the error
      });
  };

  const getInvoices = () => {
    axios
      .get(`${BASE_URL}/Invoice/`)
      .then((response) => {
        setInvoice(response);
      })
      .catch((error) => {
        console.error("Request failed:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getInvoices();
        await getProducts();
        console.log("getinvoice called successfully");
      } catch (error) {
        console.error("Error calling getinvoice:", error);
      }
    };

    fetchData();
  }, [refreshData]);

  const updateInputValue = (value, price) => {
    validation.setValues({
      ...validation.values,
      description: value,
      unitPrice: price,
      date: formattedDate,
    });
  };

  const handleCustomerClicks = () => {
    setIsEdit(false);
    // setInvoice("");
    toggle();
  };

  return (
    <React.Fragment>
      <CustomersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        editDetails={editDetails}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Customers" /> */}
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={invoice || []}
                    isGlobalFilter={true}
                    isAddButton={true}
                    isExpButton={false}
                    isPagination={true}
                    isCustomPageSize={true}
                    handleUserClick={handleCustomerClicks}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
                    buttonName=" New Invoice"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    pagination="pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader
              toggle={() => {
                setModal(false);
                setEditInvoice(null);
              }}
              tag="h4"
            >
              {!!isEdit ? "Edit Invoice" : "Add Invoice"}
            </ModalHeader>
            <ModalBody>
              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Description</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Sub Total</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formDataArray.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <h5 className="text-truncate font-size-14">
                              {data.description}
                            </h5>
                          </div>
                        </td>
                        <td>{data.quantity}</td>
                        <td>{data.unitPrice}</td>
                        <td>{data.quantity * data.unitPrice}</td>
                        <td
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleRemoveFromArray(index)}
                        >
                          X
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col>
                    <div className="text-end">
                      <h3>Total : {total}</h3>
                    </div>
                  </Col>
                </Row>
              </div>
              <Form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={4}>
                    <div className="mb-3">
                      <Label>Description</Label>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            width: "104%",
                            marginLeft: "-10px",
                          }}
                        >
                          <Input
                            name="description"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""} // Adjust 'dropdownValue' to match the value in your validation object
                            placeholder="description"
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                          />
                        </DropdownToggle>
                        <DropdownMenu className="">
                          {product?.map((item) => (
                            <DropdownItem
                              key={item?.id}
                              href="#"
                              onClick={() =>
                                updateInputValue(
                                  item?.product?.description,
                                  item?.unitPrice
                                )
                              }
                            >
                              {item?.product?.description},{item?.color?.name},
                              {item?.category?.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                    {/* <div className="mb-3">
                      <Label>Joining Date</Label>
                      <Flatpickr
                        type="date"
                        name="joiningDate"
                        className="form-control d-block"
                        placeholder="Select time"
                        options={{
                          mode: "single",
                          dateFormat: 'd M, Y',
                        }}
                        value={validation.values.joiningDate}
                        onChange={(date: any) => validation.setFieldValue("joiningDate", moment(date[0]).format("DD MMM ,YYYY"))}
                      />
                      {validation.touched.joiningDate && validation.errors.joiningDate ?
                        (<span className="text-danger"> {validation.errors.joiningDate}  </span>) : null}
                    </div> */}
                  </Col>

                  <Col xs={4}>
                    <div className="mb-3">
                      <Label> Quantity </Label>
                      <Input
                        style={{ marginTop: 8 }}
                        type="number"
                        name="quantity"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.quantity || ""}
                        placeholder="Insert quantity"
                        invalid={
                          validation.touched.quantity &&
                          validation.errors.quantity
                            ? true
                            : false
                        }
                      />
                      {validation.touched.quantity &&
                      validation.errors.quantity ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.quantity}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="mb-3">
                      <Label>Unit Price </Label>
                      <Input
                        style={{ marginTop: 8 }}
                        type="number"
                        name="unitPrice"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.unitPrice || ""}
                        placeholder="Insert unitPrice"
                        invalid={
                          validation.touched.unitPrice &&
                          validation.errors.unitPrice
                            ? true
                            : false
                        }
                      />
                      {validation.touched.unitPrice &&
                      validation.errors.unitPrice ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.unitPrice}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Row>
                    <Col>
                      <div className="text-end">
                        <h2
                          style={{
                            cursor: "pointer",
                            marginBottom: 3,
                            color: "blue",
                            marginTop: -12,
                          }}
                          onClick={handleAddToArray}
                        >
                          +
                        </h2>
                      </div>
                    </Col>
                  </Row>
                  <Col xs={4}>
                    <div className="mb-3">
                      <Label> Party Id </Label>
                      <Input
                        style={{ marginTop: 8 }}
                        type="number"
                        name="partyId"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.partyId || ""}
                        placeholder="Insert partyId"
                        invalid={
                          validation.touched.partyId &&
                          validation.errors.partyId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.partyId &&
                      validation.errors.partyId ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.partyId}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-invoice"
                      >
                        {!!isEdit ? "Update" : "Add"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Invoice;
