import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../Components/Common/TableContainer";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  FormGroup,
} from "reactstrap";
// import moment from "moment";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
// import Flatpickr from "react-flatpickr";

//import components
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "slices/thunk";

import SuppliersModal from "./SuppliersModal";

//redux
import { useSelector, useDispatch } from "react-redux";

// import { EcoAction } from "../type";
import { BASE_URL } from "../../helpers/url_helper";
import axios from "axios";

interface EcoAction {
  ecommerce: {
    id?: number;
    partyName?: string;
    contact?: string;
    address?: string;
    balance?: number;
    type?: string;
    loading?: boolean;
  };
}

function Supplier() {
  //meta title
  document.title = "Abu Bakar Traders | Admin & Dashboard Template";

  const dispatch = useDispatch<any>();
  const selectProperties = createSelector(
    (state: EcoAction) => state.ecommerce,
    (ecommerce) => ({
      loading: ecommerce.loading,
    })
  );
  const { loading } = useSelector(selectProperties);
  const [isLoading, setLoading] = useState(loading);

  const [modal, setModal] = useState<boolean>(false);
  const [modal1, setModal1] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [party, setParty] = useState<any>(null);
  const [editparty, setEditParty] = useState<any>(null);
  const [delparty, setDelParty] = useState<any>(null);
  const [refreshData, setRefreshData] = useState(false);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (editparty && editparty.id) || "",
      partyName: (editparty && editparty.partyName) || "",
      contact: (editparty && editparty.contact) || "",
      address: (editparty && editparty.address) || "",
      balance: (editparty && editparty.balance) || "",
      accountCode: (editparty && editparty.accountCode) || "",
      accountTitle: (editparty && editparty.accountTitle) || "",
      iban: (editparty && editparty.iban) || "",
    },
    validationSchema: Yup.object({
      partyName: Yup.string().required("Please Enter Your User Name"),
      contact: Yup.number().required("Please Enter Your contact"),
      address: Yup.string().required("Please Enter Your address"),
      balance: Yup.number().required("Please Enter Your balance"),
      accountCode: Yup.string().required("Please Enter Your accountCode"),
      accountTitle: Yup.string().required("Please Enter Your accountTitle"),
      iban: Yup.string().required("Please Enter Your iban"),
    }),
    onSubmit: (values: any) => {
      console.log("submit");
      console.log("Edit", isEdit);
      if (isEdit) {
        const updateParty = {
          id: values.id,
          partyName: values.partyName,
          contact: values.contact,
          address: values.address,
          balance: values.balance,
          accountCode: values.accountCode,
          accountTitle: values.accountTitle,
          iban: values.iban,
          type: "supplier",
        };
        // update party
        axios
          .post(`${BASE_URL}/Parties/edit`, updateParty)
          .then((response) => {
            console.log(response);
            dispatch(onUpdateOrder(updateParty));
            setRefreshData((prev) => !prev);
            setEditParty(null);
          })
          .catch((error) => {
            console.error("Editing Party failed:", error);
          });

        validation.resetForm();
      } else {
        console.log("add clicked");
        const newParty = {
          partyName: values["partyName"],
          contact: values["contact"],
          address: values["address"],
          balance: values["balance"],
          accountCode: values["accountCode"],
          accountTitle: values["accountTitle"],
          iban: values["iban"],
          type: "supplier",
        };
        // save new party
        axios
          .post(`${BASE_URL}/Parties/add`, newParty)
          .then((response) => {
            console.log(response);
            dispatch(onAddNewOrder(newParty));
            setRefreshData((prev) => !prev);
          })
          .catch((error) => {
            console.error("Adding Products failed:", error);
          });

        validation.resetForm();
      }
      toggle();
    },
  });

  const [editDetails, setEditDetails] = useState<any>("");

  const toggleViewModal = useCallback(
    (data: any) => {
      setModal1(!modal1);
      setEditDetails(data);
    },
    [modal1]
  );

  // useEffect(() => {
  //   if (orders && !orders.length) {
  //     dispatch(onGetOrders());
  //   }
  // }, [dispatch, orders]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // setParty(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleOrderClick = useCallback(
    (arg: any) => {
      const party = arg;
      setEditParty({
        id: party.id,
        partyName: party.partyName,
        contact: party.contact,
        address: party.address,
        balance: party.balance,
        accountCode: party.Accounts[0]?.accountCode,
        accountTitle: party.Accounts[0]?.accountTitle,
        iban: party.Accounts[0]?.iban,
        type: "supplier",
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  //delete party
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (party: any) => {
    setDelParty(party);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (delparty.id) {
      console.log("delete id", delparty.id);

      axios
        .post(`${BASE_URL}/Parties/delete`, { partyId: delparty.id })
        .then((response) => {
          console.log("party deleted successfully", response);
          dispatch(onDeleteOrder(delparty.id));
          setRefreshData((prev) => !prev);
        })
        .catch((error) => {
          console.error("Delete failed:", error);
        });
      // dispatch(onDeleteSupplier(product.id));
    }

    setDeleteModal(false);
  };
  const handleOrderClicks = () => {
    setIsEdit(false);
    // setParty("")
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        header: () => {
          return (
            <FormGroup check className="font-size-16">
              <Label check>
                <Input type="checkbox" id="checkAll" />
              </Label>
            </FormGroup>
          );
        },
        accessorKey: "id",
        cell: () => (
          <FormGroup check className="font-size-16">
            <Label check>
              <Input type="checkbox" id="checkAll" />
            </Label>
          </FormGroup>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: 'Order ID',
      //   accessorKey: 'id',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps: any) => {
      //     return <Link to="#" className="text-body fw-bold">{cellProps.row.original.id}</Link>
      //   }
      // },
      // {
      //   header: 'Party ID',
      //   accessorKey: 'id',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Supplier Name",
        accessorKey: "partyName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Balance",
        accessorKey: "balance",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Contact",
        accessorKey: "contact",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Address",
        accessorKey: "address",
        enableColumnFilter: false,
        enableSorting: true,
      },

      // {
      //   header: 'Password',
      //   accessorKey: 'Password',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },

      {
        header: "Account",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                const partyData = cellProps.row.original;
                toggleViewModal(partyData);
              }}
            >
              View Details
            </Button>
          );
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const partyData = cellProps.row.original;
                  handleOrderClick(partyData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                <UncontrolledTooltip placement="top" target="editTooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const partyData = cellProps.row.original;
                  onClickDelete(partyData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                <UncontrolledTooltip placement="top" target="deleteTooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [handleOrderClick, toggleViewModal]
  );

  const getParties = () => {
    axios
      .get(`${BASE_URL}/Parties/supplier`)
      .then((response) => {
        console.log("API response:", response);
        setParty(response);
        // Handle the response data
      })
      .catch((error) => {
        console.error("Request failed:", error);
        // Handle the error
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getParties();
        console.log("getParties called successfully");
      } catch (error) {
        console.error("Error calling getParties:", error);
      }
    };

    fetchData();
  }, [refreshData]);
  return (
    <React.Fragment>
      <SuppliersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        editDetails={editDetails}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Users" /> */}
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={party || []}
                      isGlobalFilter={true}
                      isAddButton={true}
                      isExpButton={false}
                      isCustomPageSize={true}
                      handleUserClick={handleOrderClicks}
                      isPagination={true}
                      SearchPlaceholder="26 records..."
                      buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addOrder-modal"
                      buttonName=" Add New Supplier"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader
              toggle={() => {
                setModal(false);
                setEditParty(null);
              }}
              tag="h4"
            >
              {!!isEdit ? "Edit Supplier" : "Add Supplier"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    {/* <div className="mb-3">
                      <Label>Order Id</Label>
                      <Input
                        name="id"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.id || ""}
                        invalid={
                          validation.touched.id && validation.errors.id ? true : false
                        }
                      />
                      {validation.touched.id && validation.errors.id ? (
                        <FormFeedback type="invalid">{validation.errors.id}</FormFeedback>
                      ) : null}
                    </div> */}
                    <div className="mb-3">
                      <Label>Supplier Name</Label>
                      <Input
                        name="partyName"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.partyName || ""}
                        invalid={
                          validation.touched.partyName &&
                          validation.errors.partyName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.partyName &&
                      validation.errors.partyName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.partyName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    {/* <div className="mb-3">
                      <Label>Order Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        id="orderDate"
                        name="orderDate"
                        placeholder="Select date"
                        options={{
                          mode: "single",
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(kycBirthDate: any) => validation.setFieldValue("orderDate", moment(kycBirthDate[0]).format("DD MMMM ,YYYY"))}
                        value={validation.values.orderDate || ''}
                      />
                      {validation.touched.orderDate && validation.errors.orderDate ? (
                        <span className="text-danger">{validation.errors.orderDate}</span>
                      ) : null}
                    </div> */}
                    <div className="mb-3">
                      <Label>Contact</Label>
                      <Input
                        name="contact"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contact || ""}
                        invalid={
                          validation.touched.contact &&
                          validation.errors.contact
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contact &&
                      validation.errors.contact ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contact}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Address</Label>
                      <Input
                        name="address"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                          validation.touched.address &&
                          validation.errors.address
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address &&
                      validation.errors.address ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Balance</Label>
                      <Input
                        name="balance"
                        type="number"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.balance || ""}
                        invalid={
                          validation.touched.balance &&
                          validation.errors.balance
                            ? true
                            : false
                        }
                      />
                      {validation.touched.balance &&
                      validation.errors.balance ? (
                        <FormFeedback type="invalid">
                          {validation.errors.balance}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Account Code</Label>
                      <Input
                        name="accountCode"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.accountCode || ""}
                        invalid={
                          validation.touched.accountCode &&
                          validation.errors.accountCode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.accountCode &&
                      validation.errors.accountCode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.accountCode}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Account Title</Label>
                      <Input
                        name="accountTitle"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.accountTitle || ""}
                        invalid={
                          validation.touched.accountTitle &&
                          validation.errors.accountTitle
                            ? true
                            : false
                        }
                      />
                      {validation.touched.accountTitle &&
                      validation.errors.accountTitle ? (
                        <FormFeedback type="invalid">
                          {validation.errors.accountTitle}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Iban</Label>
                      <Input
                        name="iban"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.iban || ""}
                        invalid={
                          validation.touched.iban && validation.errors.iban
                            ? true
                            : false
                        }
                      />
                      {validation.touched.iban && validation.errors.iban ? (
                        <FormFeedback type="invalid">
                          {validation.errors.iban}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-user"
                      >
                        {!!isEdit ? "Update" : "Add"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
export default Supplier;
