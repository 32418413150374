import React, { useCallback, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import AddDeviceModal from './AddDeviceModal'
// Image
// import img7 from "../../../assets/images/product/img-7.png"
// import img4 from "../../../assets/images/product/img-4.png"

const EcommerenceOrdersModal = (props?: any) => {
  const [modal1, setModal1] = useState<boolean>(false);
  const { isOpen, toggle, editDetails } = props;
  const toggleViewModal = useCallback((data: any) => { setModal1(!modal1);  }, [modal1]);
  // console.log("edit details",editDetails.Accounts[0].accountCode);
  const accountCode = editDetails?.Accounts?.[0]?.accountCode || '';
  const accountTitle = editDetails?.Accounts?.[0]?.accountTitle || '';
  const iban = editDetails?.Accounts?.[0]?.iban || '';

  return (
    <>
    {/* <AddDeviceModal isOpen={modal1} toggle={toggleViewModal} editDetails={editDetails} /> */}
    <Modal isOpen={isOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex={-1} toggle={toggle} >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>
          <h5 className="modal-title">Account Details</h5></ModalHeader>
        <ModalBody>
          {/* <p className="mb-2">
            User Name: <span className="text-primary">{editDetails?.Name}</span>
          </p>
          <p className="mb-4">
            User Email: <span className="text-primary">{editDetails?.billingName}</span>
          </p> */}
         
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">Account Code</th>
                  <th scope="col">Account Title</th>
                  <th scope="col">Iban</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <th scope="row">
                    <div>
                      <img src={img7} alt="" className="avatar-sm" />
                    </div>
                  </th> */}
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                      {accountCode}
                      </h5>
                      {/* <p className="text-muted mb-0">$ 225 x 1</p> */}
                    </div>
                  </td>
                  <td>{accountTitle}</td>
                  <td>{iban}</td>
                </tr>
               
                {/* <tr>
                  <td colSpan={2}>
                    <h6 className="m-0 text-right">Sub Total:</h6>
                  </td>
                  <td>{editDetails?.total}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h6 className="m-0 text-right">Shipping:</h6>
                  </td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h6 className="m-0 text-right">Total:</h6>
                  </td>
                  <td>{editDetails?.total}</td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </div>
    </Modal>
    </>
  );
}

export default EcommerenceOrdersModal
