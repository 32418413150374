import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Input, FormFeedback, Label, Form, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";

//Import Breadcrumb

import DeleteModal from "../../Components/Common/DeleteModal";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

import { PatternFormat } from "react-number-format";


//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { createSelector } from 'reselect';
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";

const Country = () => {
  //meta title
  document.title = "Customers | Skote - React Admin & Dashboard Template";

  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: any) => state.ecommerce,
    (ecommerce) => ({
      customers: ecommerce.customers,
      loading: ecommerce.loading
    })
  );

  const { customers, loading }: any = useSelector(selectProperties);

  const [isLoading, setLoading] = useState<boolean>(loading)

  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [customer, setCustomer] = useState<any>(null);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      country: (customer && customer.country) || "",
      factor: (customer && customer.factor) || "",
     
    },

    validationSchema: Yup.object({
      country: Yup.string().required("Please Enter Your Country"),
      factor: Yup.number().required("Please Enter Your Factor"),
     
    }),
    onSubmit: (values: any) => {

      if (isEdit) {
        const updateCustomer = {
          id: customer ? customer.id : 0,
          country: values.country,
          factor: values.factor,
        };
        // update customer
        dispatch(onUpdateCustomer(updateCustomer));
        validation.resetForm();
      } else {
        const newCustomer = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          country: values["country"],
          factor: values["factor"],
          // country: values["country"],
          // email: values["email"],
          // phone: values["phone"],
          // address: values["address"],
          // rating: values["rating"],
          // factor: values["factor"],
          // joiningDate: values['joiningDate'],
        };
        // save new customer
        dispatch(onAddNewCustomer(newCustomer));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleCustomerClick = useCallback((arg: any) => {
    const customer = arg;

    setCustomer({
      id: customer.id,
      country: customer.country,
      factor: customer.factor,
      // country: customer.country,
      // email: customer.email,
      // phone: customer.phone,
      // address: customer.address,
      // rating: customer.rating,
      // factor: customer.factor,
      // joiningDate: customer.joiningDate,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const data = [
    { id:"1",country: "Pakistan", factor: "1.2" },
    { id:"2",country: "India", factor: "1.5" },
    { id:"3",country: "USA", factor: "1.8" },
    { id:"4",country: "China", factor: "1.3" },
    { id:"5",country: "Australia", factor: "1.6" }
  ];

  // Customber Column
  const columns = useMemo(
    () => [
      {
        header: "Country",
        accessorKey: "country",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Factor",
        accessorKey: "factor",
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: "Rating",
      //   accessorKey: "rating",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps: any) => {
      //     return <span className="badge bg-success font-size-12"><i className="mdi mdi-star me-1"></i> {cellProps.getValue()}</span>
      //   }
      // },
      // {
      //   header: "Carbon Credits",
      //   accessorKey: "factor",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Dollar Amount",
      //   accessorKey: "factor",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      // {
      //   header: "Joining Date",
      //   accessorKey: "joiningDate",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop"> <i className="mdi mdi-dots-horizontal font-size-18"></i></DropdownToggle>
              <DropdownMenu className="">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }}>
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i> Edit
                  <UncontrolledTooltip placement="top" target="edittooltip"> Edit </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  href="#"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }} >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip" ></i>Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip"> Delete</UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [handleCustomerClick]
  );

  //delete customer
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (customer: any) => {
    setCustomer(customer);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
    if (customer.id) {
      dispatch(onDeleteCustomer(customer.id));
    }
    setDeleteModal(false);
  };

  useEffect(() => {
    dispatch(onGetCustomers());
  }, [dispatch]);

  const handleCustomerClicks = () => {
    setIsEdit(false);
    setCustomer("");
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Customers" /> */}
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={data || []}
                      isGlobalFilter={true}
                      // isAddButton={true}
                      isPagination={true}
                      isCustomPageSize={true}
                      handleUserClick={handleCustomerClicks}
                      buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
                      buttonName=" New Customers"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      pagination="pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Customer" : "Add Customer"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>Country</Label>
                      <Input
                        name="country"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.country || ""}
                        placeholder="Insert country name"
                        invalid={
                          validation.touched.country && validation.errors.country ? true : false
                        }
                      />
                      {validation.touched.country && validation.errors.country ?
                        (<FormFeedback type="invalid">{validation.errors.country}</FormFeedback>) : null}
                    </div>
                    <div className="mb-3">
                      <Label> Factor </Label>
                      <Input
                        type="number"
                        name="factor"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.factor || ''}
                        placeholder="Insert factor"
                        invalid={
                          validation.touched.factor && validation.errors.factor ? true : false
                        }
                      />
                      {validation.touched.factor && validation.errors.factor ?
                        (<FormFeedback type="invalid"> {validation.errors.factor} </FormFeedback>) : null}
                    </div>

                    {/* <div className="mb-3">
                      <Label>Joining Date</Label>
                      <Flatpickr
                        type="date"
                        name="joiningDate"
                        className="form-control d-block"
                        placeholder="Select time"
                        options={{
                          mode: "single",
                          dateFormat: 'd M, Y',
                        }}
                        value={validation.values.joiningDate}
                        onChange={(date: any) => validation.setFieldValue("joiningDate", moment(date[0]).format("DD MMM ,YYYY"))}
                      />
                      {validation.touched.joiningDate && validation.errors.joiningDate ?
                        (<span className="text-danger"> {validation.errors.joiningDate}  </span>) : null}
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button type="submit" color="success" className="save-customer" >{!!isEdit ? "Update" : "Add"}</Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Country;
