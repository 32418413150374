import React, { useState } from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper, Popup } from "google-maps-react"
import { connect } from "react-redux"
import LightData from "./LightData"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb"

const LoadingContainer = () => <div>Loading...</div>

const MapsGoogle = props => {

    //meta title
    document.title = "Google Maps | Skote - React Admin & Dashboard Template";

    // const selectedPlace: any = {}

    // const onMarkerClick = (a: any, b: any, c: any) => {
    //     alert("You clicked in this marker")
    // }
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);

    const handleMarkerClick = (place) => {
        setSelectedPlace(place);
        setActiveMarker(place);
        setShowingInfoWindow(true);
    };

    const handleInfoWindowClose = () => {
        setShowingInfoWindow(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Maps" breadcrumbItem="Google Maps" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Devices</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Location of devices.
                                    </CardSubtitle>
                                    <div
                                        id="gmaps-markers"
                                        className="gmaps"
                                        style={{ position: "relative" }}
                                    >
                                        <Map
                                            google={props.google}
                                            style={{ width: "100%", height: "100%" }}
                                            zoom={14}
                                        // onClick={onMapClicked}
                                        >
                                            <Marker
                                                title={"The marker`s title will appear as a tooltip."}
                                                name={"SOMA"}
                                                onClick={() =>
                                                    handleMarkerClick({
                                                        name: 'SOMA',
                                                        position: { lat: 37.778519, lng: -122.40564 },
                                                    })
                                                }
                                                position={{ lat: 37.778519, lng: -122.40564 }}
                                            >
                                                <Popup>SOMA</Popup>
                                            </Marker>
                                            <Marker onClick={() =>
                                                handleMarkerClick({
                                                    name: 'SOMA',
                                                    position: { lat: 37.778519, lng: -122.40564 },
                                                })
                                            }
                                                name={'Current location'} />
                                            <InfoWindow
                                                marker={activeMarker}
                                                visible={true}>
                                                <div>
                                                    <h1>SOMA</h1>
                                                </div>
                                            </InfoWindow>
                                        </Map>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        {/* <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Overlays</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Example of google maps.
                                    </CardSubtitle>
                                    <div
                                        id="gmaps-overlay"
                                        className="gmaps"
                                        style={{ position: "relative" }}
                                    >
                                        <Map
                                            google={props.google}
                                            zoom={14}
                                            style={{ width: "100%", height: "100%" }}
                                            initialCenter={{
                                                lat: 40.854885,
                                                lng: -88.081807,
                                            }}
                                        >
                                            <Marker
                                                onClick={(a: any, b: any, c: any) => {
                                                    onMarkerClick(a, b, c)
                                                }}
                                            />
                                            <InfoWindow>
                                                <div>
                                                    <h1>{selectedPlace.name}</h1>
                                                </div>
                                            </InfoWindow>
                                        </Map>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>

                    {/* <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Basic</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Example of google maps.
                                    </CardSubtitle>
                                    <div
                                        id="gmaps-markers"
                                        className="gmaps"
                                        style={{ position: "relative" }}
                                    >
                                        <Map
                                            google={props.google}
                                            zoom={14}
                                            style={{ width: "100%", height: "100%" }}
                                        >
                                            <InfoWindow>
                                                <div>
                                                    <h1>{selectedPlace.name}</h1>
                                                </div>
                                            </InfoWindow>
                                        </Map>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Ultra Light</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Example of google maps.
                                    </CardSubtitle>
                                    <div
                                        id="gmaps-overlay"
                                        className="gmaps"
                                        style={{ position: "relative" }}
                                    >
                                        <Map
                                            google={props.google}
                                            zoom={14}
                                            styles={LightData.Data}
                                            style={{ width: "100%", height: "100%" }}
                                        >
                                            <Marker
                                                onClick={(a: any, b: any, c: any) => {
                                                    onMarkerClick(a, b, c)
                                                }}
                                            />
                                            <InfoWindow>
                                                <div>
                                                    <h1>{selectedPlace.name}</h1>
                                                </div>
                                            </InfoWindow>
                                        </Map>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default connect(
    null,
    {}
)(
    GoogleApiWrapper({
        apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
        LoadingContainer: LoadingContainer,
        v: "3",
    })(MapsGoogle)
)
