import React, { Component } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "../../../../node_modules/leaflet/dist/leaflet.css";
import Leaflet from "leaflet"
import slack from "../../../assets/images/brands/pin.png";
// Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

// delete Leaflet.Icon.Default.prototype._getIconUrl

// Leaflet.Icon.Default.mergeOptions({
//   iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
//   shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
// })
const customMarkerIcon = Leaflet.icon({
  iconUrl: slack,
  iconSize: [32, 32], // Adjust the size of the icon as needed
});
export default class SimpleMap extends Component {
  state = {
    markers: [
      { id: 1, lat: 51.505, lng: -0.09, name: "Device 1" },
      { id: 2, lat: 51.51, lng: -0.1, name: "Device 2" },
      { id: 3, lat: 51.515, lng: -0.08, name: "Device 3" }
    ],
    zoom: 13
  };

  render() {
    const { markers, zoom } = this.state;

    return (
      <MapContainer
        center={[markers[0].lat, markers[0].lng]}
        zoom={zoom}
        style={{ height: "300px" }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers.map(marker => (
          <Marker key={marker.id} position={[marker.lat, marker.lng]} icon={customMarkerIcon}>
            <Popup>{marker.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    );
  }
}
